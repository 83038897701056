import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIEndPoints } from 'src/assets/const/config';

@Injectable({
  providedIn: 'root'
})
export class GetVehicleDisplayService {

  constructor(private http: HttpClient) { }
  
  getVehicle = (vehicleId: any, urlHash: any) => {
    return this.http.get(APIEndPoints.stickerEndpoints.baseUrl + APIEndPoints.stickerEndpoints.vehicle +'/'+ vehicleId+'/'+urlHash, 
      { observe: 'response' });
  }
}
