import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent{
  data: any;
  noData = false;
  constructor(private route: ActivatedRoute){
    this.route.params.subscribe( params => this.data=params);
    //console.log(this.data)
    if(this.data.length == undefined){
      this.noData = true;
    }
  }

}
