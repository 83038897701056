import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './component/error/error.component';
import { VehicleDisplayComponent } from './component/vehicle-display/vehicle-display.component';

const routes: Routes = [
  {'path':"", children:[
    { "path":"" ,redirectTo:"error", pathMatch: 'full'},
    {'path':"error", component:ErrorComponent},
  ]},
  {'path':"vdp/:vehicleId/:urlHash", component:VehicleDisplayComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
