<body class="page-notFound pageType-ContentPage template-pages-ErrorPageTemplate pageLabel-notFound smartedit-page-uid-notFound ">

	<main class="pf-container " data-currency-iso-code="CAD">
            <a href="#skip-to-content" class="skiptocontent" data-role="none">text.skipToContent</a>
            <a href="#skiptonavigation" class="skiptonavigation" data-role="none">text.skipToNavigation</a>

            <div class="sticky-top-section fixed-top-section">
                <div class="yCmsContentSlot">
</div><header class="pf-header pf-header-outer">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="header-wrapper vue-apr-history">
                    <div class="header-wrapper-itm header-wrapper-left">
                        <span class="pf-header-dealership"></span>
                    </div>
                    <div class="yCmsComponent yComponentWrapper header-wrapper-itm site-logo">
<div class="banner__component simple-banner">
	<img title="Pfaff logo" alt="Pfaff logo" src="../../../assets/images/PFAFF-LAD.png">
		</div></div><div class="header-wrapper-itm header-wrapper-right">
                        <div class="header-inner">
                            <div class="header-component">
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hidden-xs hidden-sm js-secondaryNavAccount collapse" id="accNavComponentDesktopOne">
        <ul class="nav__links">

        </ul>
    </div>
    <div class="hidden-xs hidden-sm js-secondaryNavCompany collapse" id="accNavComponentDesktopTwo">
        <ul class="nav__links js-nav__links">

        </ul>
    </div>
</header>

<div id="loading-indicator-container" class="loading-indicator-container pf-hidden">
    <div>
        <span class="pf-loading-indicator mb-10"></span>
    </div>
    <div id="loading-indicator-message" class="pf-loading-message body-2"></div>
</div>

<div class="yCmsContentSlot">
</div></div>

            <a id="skip-to-content"></a>

            <section class="pf-content-section pfaff-content">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="content-wrapper">
                                <div class="main__inner-wrapper">
                                    <div class="yCmsContentSlot">
<div class="yCmsComponent">
<div class="vehicle">
    <div class="vehicle-notification">
        <h2 class="title-h2">Please Visit us using a vaild link</h2>
    </div>
</div></div></div></div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer class="pf-footer">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="footer-wrapper">
                    </div>
            </div>
        </div>
    </div>
</footer>
</main>

</body>