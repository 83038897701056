import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { GetVehicleDisplayService } from 'src/app/service/get-vehicle-display/get-vehicle-display.service';
import { APIEndPoints } from 'src/assets/const/config';
declare var $: any;



@Component({
  selector: 'app-vehicle-display',
  templateUrl: './vehicle-display.component.html',
  styleUrls: ['./vehicle-display.component.css']
})
export class VehicleDisplayComponent implements OnInit {
  file_name = ""
  data: any;
  isLoading = false;
  noData = false;
  yesData = true;
  json_data = {
    "dealershipName": "",
    "status": "",
    "vin": "",
    "stockNumber": "",
    "currentKms": 0,
    "year": 0,
    "inventoryPictures": [],
    "listPrice": 0,
    "savings":0,
    "bestPrice": 0,
    "nameAndModel": "",
    "edition": "",
    "engine": "",
    "horsePower": "",
    "engineType": "",
    "transmission": "",
    "dealerOpts": "",
    "totalCostDealerOpts": "",
    "msrpOptions": "",
    "vehicleName": "",
    "dealerName": "",
    "dealershipAddress": "",
    "dealerEmail": "",
    "dealerPhone": "",
    "appUrl": "",
    "cityFuelEconomy":"",
    "hwyFuelEconomy": "",
    "driveTrain":"",
    "url": ""
    }
  constructor(private getVehicleDisplayService: GetVehicleDisplayService,private route: ActivatedRoute) {
    this.route.params.subscribe( params => this.data=params);
    
   }
  subscription: Subscription | undefined;
  setLogo(json_data: any) : void {
    if(json_data.dealerName =="Sisley For Honda"){
      this.file_name = "../../../assets/images/lithia.png";
      $('#pfafflogo').addClass("logo-lithia")
    }
    else{
      this.file_name = "../../../assets/images/PFAFF-LAD.png";
    }
  }
  ngOnInit(): void {
    this.isLoading = true;
    this.subscription = this.getVehicleDisplayService.getVehicle(this.data.vehicleId,this.data.urlHash).subscribe((response: any) => {
        this.json_data = response.body;
        load(this.json_data);
        this.isLoading = false;
        this.setLogo(this.json_data);
        
    }
    ,(error: any)=>{
      this.yesData = false;
      this.isLoading = false;
      this.noData = true;
      this.setLogo({"dealerName":"NO"});
      //console.log(error.error.text);
    });
    
    function load(json_data: any) {
      //console.log("J")
      //console.log(json_data)
      var content = "";

        for(var i in json_data["inventoryPictures"]){
        var img = json_data["inventoryPictures"][i].picture;
        var alt = '';
        content += "<div class='item'><img src=\"" +img+ "\" alt=\"" +alt+ "\"></div>"
        }

        $("#owl-demo").html(content);
        
        $("#owl-demo").owlCarousel({
          rtl:true,
          loop:true,
          dots: false,
          nav:true,
          navText:["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
          items:1
          
        });
        $('#owl-demo').trigger('refresh.owl.carousel');

        $('#expandBtn').click(function(e: any){
          $( ".vehicle-msrp" ).toggleClass( "vehicle-full-list" );
          $( ".vehicle-show-options" ).toggleClass( "open" );
        });


        var vehicleName = ''
        if(json_data['vehicleName'])
          vehicleName = json_data['vehicleName'];
        var vdpUrl = window.location.href;
        var dealershipName = ''
        if(json_data['dealerName']){
           dealershipName = json_data['dealerName'];
        }
        var dealershipAddress = ''
        if(json_data['dealerAddress'])
           dealershipAddress = json_data['dealerAddress'];
        var dealerEmail =''
        if(json_data['dealerEmail'])
          dealerEmail = json_data['dealerEmail'];
        var dealerPhone = ''
        if(json_data['dealerPhone']) 
          dealerPhone = json_data['dealerPhone'];
        $('#submitBtnMail').prop('disabled', true);
        $('#testDriveBtn').prop('disabled', true);
        $('#submitBtnSaleHelp').prop('disabled', true);

        var phoneNumber = $('#emailPhoneNumber');
        phoneNumber.mask('000-000-0000');
        var phoneNumberOne = $('#phoneNumber');
        phoneNumberOne.mask('000-000-0000');
        var phoneNumberTwo  = $('#phoneNumberHelp');
        phoneNumberTwo.mask('000-000-0000');


        $('form').change(function() {
            if (($('#mailFirstName').val() == '') || ($('#mailLastName').val() == '') || ($('#mailEmail').val() == '')){
              $('#submitBtnMail').prop('disabled', true);
            }
            else{
              $('#submitBtnMail').prop('disabled', false);
            }
            //console.log()
            if (($('#firstName').val() == '') || ($('#lastName').val() == '') || ($('#email').val() == '') ){
              $('#testDriveBtn').prop('disabled', true);
            }
            else{
              $('#testDriveBtn').prop('disabled', false);
            }
            //console.log()
            if (($('#firstNameHelp').val() == '') || ($('#lastNameHelp').val() == '') || ($('#emailHelp').val() == '')){
              $('#submitBtnSaleHelp').prop('disabled', true);
            }
            else{
              $('#submitBtnSaleHelp').prop('disabled', false);
            }
        });

        $('#submitBtnMail').click(function(e: any){
              e.preventDefault();
              let data = {
                      "type": "mailLink",
                      "customerName": $('#mailFirstName').val()+' '+$('#mailLastName').val(),
                      "customerEmail": $('#mailEmail').val(),
                      "customerPhone": $('#emailPhoneNumber').val(),
                      "customerComments": $('#emailComments').val(),
                      "customerConsent": "true",
                      "vehicleName": vehicleName,
                      "vdpUrl": vdpUrl,
                      "dealershipName": dealershipName,
                      "dealershipAddress": dealershipAddress,
                      "dealerEmail": dealerEmail,
                      "dealerPhone": dealerPhone,
                      "stockNumber": json_data['stockNumber'], 
                      "emailMyself": ($("#mailConsentSH").is(':checked'))? true:false,
                      "emailTestDrive": false,
                      "emailConsent": false
                  }
            //console.log(data)
            return ajaxCalling(data, e, "Mail")
        });
        $('#testDriveBtn').click(function(e: any){
              e.preventDefault();
              let data = {
                      "type": "testDrive",
                      "customerName": $('#firstName').val()+' '+$('#lastName').val(),
                      "customerEmail": $('#email').val(),
                      "customerPhone": $('#phoneNumber').val(),
                      "customerComments": $('#comments').val(),
                      "customerConsent": "true",
                      "vehicleName": vehicleName,
                      "vdpUrl": vdpUrl,
                      "dealershipName": dealershipName,
                      "dealershipAddress": dealershipAddress,
                      "dealerEmail": dealerEmail,
                      "dealerPhone": dealerPhone,
                      "stockNumber": json_data['stockNumber'],
                      "emailMyself": ($("#mailMySelfTD").is(':checked'))? true:false,
                      "emailTestDrive": true,
                      "emailConsent": ($("#mailConsentTD").is(':checked'))? true:false
                  }
            return ajaxCalling(data, e,"testDrive")
        });
        $('#submitBtnSaleHelp').click(function(e: any){
              e.preventDefault();
              let data = {
                      "type": "saleHelpLink",
                      "customerName": $('#firstNameHelp').val()+' '+$('#lastNameHelp').val(),
                      "customerEmail": $('#emailHelp').val(),
                      "customerPhone": $('#phoneNumberHelp').val(),
                      "customerComments": $('#commentsHelp').val(),
                      "customerConsent": "true",
                      "vehicleName": vehicleName,
                      "vdpUrl": vdpUrl,
                      "dealershipName": dealershipName,
                      "dealershipAddress": dealershipAddress,
                      "dealerEmail": dealerEmail,
                      "dealerPhone": dealerPhone,
                      "stockNumber": json_data['stockNumber'],
                      "emailMyself": ($("#mailMyself").is(':checked'))? true:false,
                      "emailTestDrive": ($("#mailTestdrive").is(':checked'))? true:false,
                      "emailConsent": ($("#mailConsent").is(':checked'))? true:false
                  }
            return ajaxCalling(data, e, "SaleHelp")
        });
        function ajaxCalling(data: any, e: any, type: any){
              e.preventDefault();
              //var MailKey = sha1("validUser"+data['vdpUrl']+data['stockNumber'])
              ////console.log(MailKey)
              let urlData = data['vdpUrl'].split("/")
              let hash = urlData[urlData.length-1];
              let vehicleId = urlData[urlData.length - 2];
              //console.log()
              $.ajax({
              type:"POST",
              dataType:'json',
              contentType: "application/json",
              data: JSON.stringify(data),
              url: APIEndPoints.stickerEndpoints.baseUrl+"mail/"+vehicleId+"/"+hash,
              success: function (result: any, status: any, xhr: any){
                //alert("Your request has been submitted successfully");
                $('#successBtn').trigger('click');
                $('#success-modal-body').html('<h3>Your request has been submitted successfully !! </h3>');
                resetValue(type);
              },
              error: function(xhr: any, status: any, error: any){
                
                $('#failBtn').trigger('click');
                $('#fail-modal-body').html("<h3>Please contact sales team : "+data['dealerPhone']+".</h3>");
              }

          });

        }
        function resetValue(type: any){
          if(type=="Mail"){
            console.log($('#mailLinkForm'))
           $('#mailLinkForm')[0].reset();
          }
          else if( type=="SaleHelp"){
            $('#saleshelpform')[0].reset();
          }else if( type == "testDrive"){
            $('#testDriveForm')[0].reset();
          }
        }

      };
      }
    }
    

