<body *ngIf='noData' class="page-notFound pageType-ContentPage template-pages-ErrorPageTemplate pageLabel-notFound smartedit-page-uid-notFound ">

	<main class="pf-container " data-currency-iso-code="CAD">
            <a href="#skip-to-content" class="skiptocontent" data-role="none">text.skipToContent</a>
            <a href="#skiptonavigation" class="skiptonavigation" data-role="none">text.skipToNavigation</a>

            <div class="sticky-top-section fixed-top-section">
                <div class="yCmsContentSlot">
</div><header class="pf-header pf-header-outer">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="header-wrapper vue-apr-history">
                    <div class="header-wrapper-itm header-wrapper-left">
                        <span class="pf-header-dealership"></span>
                    </div>
                    <div class="yCmsComponent yComponentWrapper header-wrapper-itm site-logo">
<div class="banner__component simple-banner">
	<img id="pfafflogoerror" title="Pfaff logo" alt="Pfaff logo" src="{{file_name}}">
		</div></div><div class="header-wrapper-itm header-wrapper-right">
                        <div class="header-inner">
                            <div class="header-component">
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hidden-xs hidden-sm js-secondaryNavAccount collapse" id="accNavComponentDesktopOne">
        <ul class="nav__links">

        </ul>
    </div>
    <div class="hidden-xs hidden-sm js-secondaryNavCompany collapse" id="accNavComponentDesktopTwo">
        <ul class="nav__links js-nav__links">

        </ul>
    </div>
</header>

<div id="loading-indicator-container" class="loading-indicator-container pf-hidden">
    <div>
        <span class="pf-loading-indicator mb-10"></span>
    </div>
    <div id="loading-indicator-message" class="pf-loading-message body-2"></div>
</div>

<div class="yCmsContentSlot">
</div></div>

            <a id="skip-to-content"></a>

            <section class="pf-content-section pfaff-content">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="content-wrapper">
                                <div class="main__inner-wrapper">
                                    <div class="yCmsContentSlot">
<div class="yCmsComponent">
<div class="vehicle">
    <div class="vehicle-notification">
        <h2 class="title-h2">We’re sorry, this vehicle is no longer available.</h2>
    </div>
</div></div></div></div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer class="pf-footer">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="footer-wrapper">
                    </div>
            </div>
        </div>
    </div>
</footer>
</main>

</body>
<div id="loading" *ngIf='isLoading'>

</div>
<body class="page-productDetails pageType-ProductPage template-pages-product-productLayout2Page  smartedit-page-uid-productDetails" *ngIf='yesData' style="overflow-y: visible !important;">
    <main class="pf-container">
        <div class="sticky-top-section">
            <div class="yCmsContentSlot"></div>
            <header class="pf-header pf-header-outer">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="header-wrapper vue-apr-history">
                                <div class="header-wrapper-itm header-wrapper-left">
                                    <span class="pf-header-dealership">{{json_data.dealershipName}}</span>
                                </div>
                                <div class="yCmsComponent yComponentWrapper header-wrapper-itm site-logo">
                                    <div class="banner__component simple-banner">
                                        <img id="pfafflogo" title="Pfaff logo" alt="Pfaff logo" src="{{file_name}}">
                                    </div>
                                
                </div><div class="header-wrapper-itm header-wrapper-right">
                                    <div class="header-inner">
                                        <div class="header-component">
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hidden-xs hidden-sm js-secondaryNavAccount collapse" id="accNavComponentDesktopOne">
                    <ul class="nav__links">
            
                    </ul>
                </div>
                <div class="hidden-xs hidden-sm js-secondaryNavCompany collapse" id="accNavComponentDesktopTwo">
                    <ul class="nav__links js-nav__links">
            
                    </ul>
                </div>
            </header>
        </div>
        <section class="pf-content-section">
            <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="main__inner-wrapper">
                    <div class="vehicle">
                        <div class="vehicle-wrap">
                        <section class="vehicle-section vehicle-section-left">
                            <div class="vehicle-info">
                                <div class="vehicle-outer">
                                <div class="vehicle-col">
                                    <span class="vehicle-info-caption">Stock No:</span>
                                </div>
                                <div class="vehicle-col">
                                    <span class="vehicle-info-stock">
                                        {{json_data.stockNumber}}</span>
                                </div>
                            </div>
                            <div class="vehicle-outer">
                                    <div class="vehicle-col">
                                        <span class="vehicle-info-caption">VIN:</span>
                                    </div>
                                    <div class="vehicle-col">
                                        <span class="vehicle-info-ttl">
                                            {{json_data.vin}}</span>
                                    </div>
                                </div>
                            <div class="vehicle-outer">
                                    <div class="vehicle-col">
                                        <span class="vehicle-info-caption">Mileage:</span>
                                    </div>
                                    <div class="vehicle-col">
                                        <span class="vehicle-info-ttl">
                                            {{json_data.currentKms}} KM</span>
                                    </div>
                                </div>
                            </div>
                                <div class="vehicle-gallery">
                                    <div class="image-gallery js-image-gallery">
                                        <div id="owl-demo" class="carousel image-gallery__image js-gallery-image owl-carousel owl-theme">
                                            <div  class="owl-wrapper-outer">
                                                
                                            </div>
                                            
                                            
                                        </div>
                                        
                                    </div>
                                </div>
                                <p class="vehicle-year">{{json_data.status}} {{json_data.year}}</p>
                                <h1 class="title-h1">{{json_data.nameAndModel}}</h1>
                                <h3 class="title-h3">{{json_data.edition}}</h3>
                                <div class="vehicle-price">
                                    <div class="vehicle-outer">
                                        <div class="vehicle-col">
                                            <span class="vehicle-text">LIST PRICE:</span>
                                        </div>
                                        <div class="vehicle-col">
                                            <span class="vehicle-price-num">${{json_data.listPrice}}</span>
                                        </div>
                                    </div>
                                    <div *ngIf="json_data.savings" class="vehicle-outer">
                                        <div class="vehicle-col">
                                            <span class="vehicle-text">PFAFF SAVINGS:</span>
                                        </div>
                                        <div class="vehicle-col">
                                            <span class="vehicle-price-num">${{json_data.savings | number: '2.'}}</span>
                                        </div>
                                    </div>
                                    <div class="vehicle-outer">
                                        <div class="vehicle-col">
                                            <span class="vehicle-text">OUR BEST PRICE:</span>
                                        </div>
                                        <div class="vehicle-col">
                                            <span class="vehicle-price-num">${{json_data.bestPrice}}</span>
                                        </div>
                                    </div>
                                </div>
                                 <p class="vehicle-text">PRICES EXCLUDE HST AND LICENSING</p>
                                <div class="vehicle-options">

                                    <div *ngIf="json_data.engineType" class="vehicle-outer">
                                        <div class="vehicle-col">
                                            <span class="vehicle-options-caption">ENGINE TYPE</span>
                                        </div>
                                        <div class="vehicle-col">
                                            <span class="vehicle-text">{{json_data.engineType}}</span>
                                        </div>
                                    </div>
                                    <div *ngIf="json_data.transmission" class="vehicle-outer">
                                        <div class="vehicle-col">
                                            <span class="vehicle-options-caption">TRANSMISSION</span>
                                        </div>
                                        <div class="vehicle-col">
                                            <span class="vehicle-text">{{json_data.transmission}}</span>
                                        </div>
                                    </div>
    
                                </div>
    
    
                            </section>
                        <section class="vehicle-section vehicle-section-middle" >
                            <div class="vehicle-info-desktop">
                                <div class="vehicle-outer">
                                    <div class="vehicle-col">
                                        <span class="vehicle-info-caption">STOCK NO:</span>
                                    </div>
                                    <div class="vehicle-col">
                                        <span class="vehicle-info-stock">{{json_data.stockNumber}}</span>
                                    </div>
                                </div>
                                <div class="vehicle-outer">
                                    <div class="vehicle-col">
                                        <span class="vehicle-info-caption">VIN :</span>
                                    </div>
                                    <div class="vehicle-col">
                                        <span class="vehicle-info-ttl">{{json_data.vin}}</span>
                                    </div>
                                </div>
                                <div class="vehicle-outer">
                                    <div class="vehicle-col">
                                        <span class="vehicle-info-caption">MILEAGE:</span>
                                    </div>
                                    <div class="vehicle-col">
                                        <span class="vehicle-info-ttl">{{json_data.currentKms}} KM</span>
                                    </div>
                                </div>
                            </div>
                            <h2 class="title-h2">Dealer Options And Charges</h2>
                            <div class="vehicle-options">
                                <span class="vehicle-info-ttl">Our best price includes: </span>
                                <p>{{json_data.dealerOpts}}</p>
                            </div>
                            <div class="vehicle-options">
                                <span class="vehicle-info-ttl">Total Dealer Charges: </span>
                                <span>{{json_data.totalCostDealerOpts}}</span>
                            </div>
                            <h2 class="title-h2">Vehicle Options</h2>
                            <div class="vehicle-options">
                                <div class="vehicle-options vehicle-msrp">
                                    <p class="vehicle-options-msrp" *ngFor="let option of json_data.msrpOptions">
                                        {{option}}
                                    </p>
                                </div>
                            </div>
                            <a class="vehicle-show-options" id="expandBtn" href="javascript:;">
                                <span class="vehicle-show">
                                    + expand</span>
                                <span class="vehicle-hide">
                                    - collapse</span>
                            </a>
    
                            </section>
    
                    </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
        </section>
        <footer class="pf-footer">
            <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="footer-wrappers">
                        <div class="pf-footer-outer">
                            <div class="pf-footer-itm">

                                <a data-toggle="modal" data-target="#myModal" id="mailLinkBtn" class="pf-footer-btn js-link-popup" title="Mail Link" data-form-container-id="mailLinkFormContainer">
                                    <span class="pf-footer-icon">
                                        <img class="pf-footer-svg" src="../../../assets/images/icon-mailLink.svg"/>
                                    </span>
                                    <span class="pf-footer-text">Share Link</span>
                                </a>
                            </div>
                            <div class="pf-footer-itm">
                                <a data-toggle="modal" data-target="#salesHelp" id="salesHelpBtn"  class="pf-footer-btn js-link-popup" title="Request Sales Help" data-form-container-id="salesHelpFormContainer">
                                    <span class="pf-footer-icon">
                                        <img class="pf-footer-svg" src="../../../assets/images/icon-salesHelp.svg"/>
                                    </span>
                                    <span class="pf-footer-text">Sales Help</span>
                                </a>
                            </div>
                            <div class="pf-footer-itm">
                                <a data-toggle="modal" data-target="#reqTestDrive" id="reqTestBtn"  class="pf-footer-btn js-link-popup" title="Request Test Drive"  data-form-container-id="testDriveFormContainer">
                                    <span class="pf-footer-icon">
                                        <img class="pf-footer-svg" src="../../../assets/images/icon-testDrive.svg"/>
                                    </span>
                                    <span class="pf-footer-text">Test Drive</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </footer>


        <div class="modal fade" id="myModal" role="dialog">
          <div class="modal-dialog " role="document">
            <div class="modal-content">

              <div class="modal-header">
                <h2 class="modal-ttl">Share Link <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button></h2>
                  
              </div>
              <div class="modal-body">
                 <div id="mailFormContainer">
                    <form method="post" id="mailLinkForm">

                     <div class="form-itm">
                         <label class="form-label">
                            <span class="form-caption">First Name:</span>
                            <input id="mailFirstName" name="firstName" class="form-inp " maxlength="30" type="text" value="">
                        </label>
                     </div>
                     <div class="form-itm">
                         <label class="form-label">
                            <span class="form-caption ">Last Name:</span>
                            <input id="mailLastName" name="lastName" class="form-inp " maxlength="30" type="text" value="">
                         </label>
                     </div>
                     <div class="form-itm">
                         <label class="form-label">
                            <span class="form-caption ">Email Address:</span>
                            <input id="mailEmail" name="email" class="form-inp " placeholder="Enter Email Address" maxlength="64" type="text" value="">
                        </label>
                     </div>
                     <div class="form-itm">
                         <label class="form-label">
                             <span class="form-caption ">Phone Number:</span>
                             <input id="emailPhoneNumber" name="phoneNumber" class="form-inp js-phone" placeholder="111-111-1111" type="text" value="" maxlength="12">
                         </label>
                     </div>
                     <div class="form-itm">
                         <label class="form-label">
                             <span class="form-caption ">Comments:</span>
                             <textarea id="emailComments" name="comments" class="form-inp form-textarea" placeholder="Enter Comments" maxlength="300"></textarea>
                         </label>
                     </div>
                     <div class="form-itm form-itm-options">
                         
                         <input id="emailConsentForm.consentTemplateId" name="consentForm.consentTemplateId" value="RECEIVE_PROMOTIONS" type="hidden">
                         <input id="emailConsentForm.consentTemplateVersion" name="consentForm.consentTemplateVersion" value="0" type="hidden">
                         <div class="form-itm form-itm-check">
                            <label class="checkbox">
                                <span class="checkbox-caption">I would like to receive exclusive offers and promotions. <a class="form-link" href="../../../assets/privacy-policy.html" target="_blank" title="View Disclaimer">View Disclaimer.</a></span>
                                <input id="mailConsentSH" name="consentForm.consentGiven" class="checkbox-inp" type="checkbox" checked="">
                                <span class="checkbox-icon"></span>
                            </label>
                        </div>
                     </div>
                     <button id="submitBtnMail" class="btn-a submitBtnMail" data-dismiss="modal" value="Submit" onsubmit="return false">Submit</button>
                     <span id="emailError-message-container" style="display:none">Sorry, an unexpected error occurred. Please try again.</span>
                 </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="salesHelp"  role="dialog">
          <div class="modal-dialog " role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h2 class="modal-ttl">Request Sales Help <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button></h2>
                
              </div>
              <div class="modal-body">
                <div id="salesHelpFormContainer">
                <form id="saleshelpform">
                    <input type="hidden" name="formName" value="salesHelp">
                    <input type="hidden" name="sourceURL" value={{json_data.url}}>
                    <div class="form-itm ">
                        <label class="form-label">
                            <span class="form-caption ">First Name:</span>
                            <input id="firstNameHelp" name="firstName" class="form-inp " maxlength="30" type="text" value="">
                        </label>
                    </div>
                    <div class="form-itm ">
                        <label class="form-label">
                            <span class="form-caption ">Last Name:</span>
                            <input id="lastNameHelp" name="lastName" class="form-inp " maxlength="30" type="text" value="">
                        </label>
                    </div>
                    <div class="form-itm ">
                        <label class="form-label">
                            <span class="form-caption ">Email Address:</span>
                            <input id="emailHelp" name="email" class="form-inp " placeholder="Enter Email Address" maxlength="64" type="text" value="">
                        </label>
                    </div>
                    <div class="form-itm ">
                        <label class="form-label">
                            <span class="form-caption ">Phone Number:</span>
                            <input id="phoneNumberHelp" name="phoneNumber" class="form-inp js-phone" placeholder="111-111-1111" type="text" value="" maxlength="12">
                        </label>
                    </div>
                    <div class="form-itm ">
                        <label class="form-label">
                            <span class="form-caption ">Comments:</span>
                            <textarea id="commentsHelp" name="comments" class="form-inp form-textarea" placeholder="Enter Comments" maxlength="300"></textarea>
                        </label>
                    </div>
                    <div class="form-itm form-itm-options">
                        <div class="form-itm form-itm-check">
                            <label class="checkbox">
                                <span class="checkbox-caption">I would like to mail this link to myself</span>
                                <input id="mailMyself" name="mailLink" class="checkbox-inp" type="checkbox">
                                <span class="checkbox-icon"></span>
                            </label>
                        </div>
                        <div class="form-itm form-itm-check">
                            <label class="checkbox">
                                <span class="checkbox-caption">I would like to schedule a test drive for this vehicle</span>
                                <input id="mailTestdrive" name="scheduleTestDrive" class="checkbox-inp" type="checkbox">
                                <span class="checkbox-icon"></span>
                            </label>
                        </div>
                        <input id="consentForm.consentTemplateIdHelp" name="consentForm.consentTemplateId" value="RECEIVE_PROMOTIONS" type="hidden">
                        <input id="consentForm.consentTemplateVersionHelp" name="consentForm.consentTemplateVersion" value="0" type="hidden">
                        <div class="form-itm form-itm-check">
                            <label class="checkbox">
                                <span class="checkbox-caption">I would like to receive exclusive offers and promotions. <a class="form-link" href="../../../assets/privacy-policy.html" target="_blank" title="View Disclaimer">View Disclaimer.</a></span>
                                <input id="mailConsent" name="consentForm.consentGiven" class="checkbox-inp" type="checkbox" checked="">
                                <span class="checkbox-icon"></span>
                            </label>
                        </div>
                    </div>
		            <button id="submitBtnSaleHelp" class="btn-a submitBtnSaleHelp"  data-dismiss="modal" type="submit" value="Submit" onsubmit="return false">Submit</button>
                    <span id="error-message-containerHelp" style="display:none">Sorry, an unexpected error occurred. Please try again.</span>
                </form>
              </div>
              </div>

            </div>
          </div>
        </div>
        <div class="modal fade" id="reqTestDrive"  role="dialog">
          <div class="modal-dialog " role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h2 class="modal-ttl">Request Test Drive<button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button></h2>
                
              </div>
              <div class="modal-body">
                <div id="testDriveFormContainer">
                 <form method="post" id="testDriveForm">
                    <input type="hidden" name="formName" value="testDrive">
                     <div class="form-itm">
                         <label class="form-label">
                            <span class="form-caption">First Name:</span>
                            <input id="firstName" name="firstName" class="form-inp " maxlength="30" type="text" value="">
                        </label>
                     </div>
                     <div class="form-itm">
                         <label class="form-label">
                            <span class="form-caption ">Last Name:</span>
                            <input id="lastName" name="lastName" class="form-inp " maxlength="30" type="text" value="">
                         </label>
                     </div>
                     <div class="form-itm">
                         <label class="form-label">
                            <span class="form-caption ">Email Address:</span>
                            <input id="email" name="email" class="form-inp " placeholder="Enter Email Address" maxlength="64" type="text" value="">
                        </label>
                     </div>
                     <div class="form-itm">
                         <label class="form-label">
                             <span class="form-caption ">Phone Number:</span>
                             <input id="phoneNumber" name="phoneNumber" class="form-inp js-phone" placeholder="111-111-1111" type="text" value="" maxlength="12">
                         </label>
                     </div>
                     <div class="form-itm">
                         <label class="form-label">
                             <span class="form-caption ">Comments:</span>
                             <textarea id="comments" name="comments" class="form-inp form-textarea" placeholder="Enter Comments" maxlength="300"></textarea>
                         </label>
                     </div>
                     <div class="form-itm form-itm-options">
                         <div class="form-itm form-itm-check">
                            <label class="checkbox">
                                <span class="checkbox-caption">I would like to mail this link to myself</span>
                                <input  id="mailMySelfTD" name="mailLink" class="checkbox-inp" type="checkbox">
                                <span class="checkbox-icon"></span>
                            </label>
                        </div>
                         <input id="consentForm.consentTemplateId" name="consentForm.consentTemplateId" value="RECEIVE_PROMOTIONS" type="hidden">
                         <input id="consentForm.consentTemplateVersion" name="consentForm.consentTemplateVersion" value="0" type="hidden">
                         <div class="form-itm form-itm-check">
                            <label class="checkbox">
                                <span class="checkbox-caption">I would like to receive exclusive offers and promotions. <a class="form-link" href="../../../assets/privacy-policy.html" target="_blank" title="View Disclaimer">View Disclaimer.</a></span>
                                <input id="mailConsentTD" name="consentForm.consentGiven" class="checkbox-inp" type="checkbox" checked="">
                                <span class="checkbox-icon"></span>
                            </label>
                        </div>
                     </div>
                     <button id="testDriveBtn" class="btn-a testDriveBtn" type="submit" data-dismiss="modal" value="Submit" onsubmit="return false">Submit</button>
                     <span id="error-message-container" style="display:none">Sorry, an unexpected error occurred. Please try again.</span>
                 </form>
              </div>
              </div>

            </div>
          </div>
        </div>
        <button class="hidden" data-toggle="modal" data-target="#success" id="successBtn"></button>
        <button class="hidden" data-toggle="modal" data-target="#failed" id="failBtn"></button>
        <div class="modal fade" id="success"  role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                    <h2 class="modal-ttl">Result <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button></h2>
                      
                  </div>
                <div class="modal-body" id="success-modal-body" >
                  
                </div>
  
              </div>
            </div>
        </div>
        <div class="modal fade" id="failed"  role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                    <h2 class="modal-ttl">Result <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button></h2>
                      
                  </div>
                <div class="modal-body" id="fail-modal-body">
                  
                </div>
  
              </div>
            </div>
          </div>

    </main>
</body>